import React from 'react';
import {
    NavigationHelpersContext,
    useNavigationBuilder,
    TabRouter,
    createNavigatorFactory,
    CommonActions,
} from '@react-navigation/native';
import {View} from 'react-native';

import PageTop from "./PageTop";
import {basicStyles} from "../styles/basic";
import {textStyles} from "../styles/text";
import AppText from "./AppText";
import InteriorTabNavLink from "./InteriorTabNavLink";

function InteriorTabNavigator({initialRouteName, children, screenOptions, backBehavior, navTitle, navIcon, styleContainer = {}, styleHeader = {}, styleTitle = {}, styleLinkContainer = {}}) {
    const {state, navigation, descriptors} = useNavigationBuilder(TabRouter, {
        children,
        screenOptions,
        initialRouteName,
        backBehavior,
    });

    return (
        <NavigationHelpersContext.Provider value={navigation}>
            <PageTop style={[
                basicStyles.flexRow,
                basicStyles.alignContentCenter,
                basicStyles.justifyContentSpaceBetween,
                basicStyles.flexWrap,
                styleContainer
            ]}>
                <View style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    styleHeader
                ]}>
                    <View style={{marginRight: 20}}>
                        {navIcon}
                    </View>
                    <AppText style={[textStyles.pageTitle, textStyles.semiBold, styleTitle]}>
                        {navTitle}
                    </AppText>
                </View>
                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, styleLinkContainer]}>
                    {
                        state.routes.map((route, routeIndex) => {
                            const routeActive = state.routes[state.index].key === route.key;

                            let borderRadiusStyle;

                            if (routeIndex === 0) {
                                borderRadiusStyle = {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                };
                            } else if(routeIndex === state.routes.length - 1) {
                                borderRadiusStyle = {
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                };
                            } else {
                                borderRadiusStyle = {
                                    borderRadius: 0
                                };
                            }

                            return (
                                <InteriorTabNavLink
                                    to={'/' + route.name}
                                    action={CommonActions.navigate(route.name)}
                                    active={routeActive}
                                    icon={React.cloneElement(descriptors[route.key].options.icon, {fill: routeActive ? '#FFFFFF' : '#0065db'})}
                                    label={descriptors[route.key].options.title || route.name}
                                    style={borderRadiusStyle}
                                />
                            )
                        })
                    }
                </View>
            </PageTop>
            <View style={basicStyles.flexScale}>
                {descriptors[state.routes[state.index].key].render()}
            </View>
        </NavigationHelpersContext.Provider>
    );

}

export const createInteriorTabNavigator = createNavigatorFactory(InteriorTabNavigator);
