import Http from '../utilities/Http';
import {API_ROOT} from '../config';

export default {
	async getJobs(organizationId, search = null, order = null, orderBy = null, postByStartDate = null, postByEndDate = null, perPage = null, page = null, paginated = 1, nonEntity = false) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/jobs', {
			params: {
				search,
				order,
				orderBy,
				postByStartDate,
				postByEndDate,
				perPage,
				page,
				paginated,
				nonEntity: nonEntity ? 1 : 0
			}
		});

		return response.data;
	},
	async getJob(organizationId, jobId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/jobs/' + jobId);

		return response.data.job;
	},
	async insertJob(organizationId, job) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/jobs/', {
			job,
		});

		return response.data.job;
	},
	async updateJob(organizationId, jobId, job, cancelToken = null) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/jobs/' + jobId, {
			job,
		}, {cancelToken});

		return response.data.job;
	},
	async updateSurveyResponse(surveyResponseId, surveyResponse) {
		let response = await Http().post(API_ROOT + '/surveys/update-survey-answers/' + surveyResponseId, {
			surveyResponse,
		});

		return response.data.surveyResponse;
	},
	async removeSurveyResponse(surveyResponseId) {
		await Http().post(API_ROOT + '/surveys/remove-survey-response/' + surveyResponseId);
	},
	async sendJobSurvey(organizationId, jobId, resend = false) {
		let url = API_ROOT + '/organizations/' + organizationId + '/jobs/send-job-survey/' + jobId;

		if (resend) {
			url += '?resend=true';
		}

		let response = await Http().get(url);

		return response.data.job;
	},
	async importJobs(organizationId, formData) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/jobs/import', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data;
	},
	async getJobsImportConfiguration(organizationId, formData) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/jobs/get-import-configuration', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data;
	},
	async getJobStatuses(organizationId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/jobs/get-job-statuses');

		return response.data.jobStatuses;
	},
};
