import React from 'react';
import {View} from 'react-native';

import {createSubNavigator} from '../../components/SubNavigator';
import Dashboard from "./Dashboard";
import Customers from './Customers';
import Reviews from './Reviews';
import Team from './Team';
import Markets from './Markets';
import {basicStyles} from '../../styles/basic';

const SubNavigator = createSubNavigator();

function Reports(props) {
	return (
		<View style={basicStyles.flexScale}>
			<SubNavigator.Navigator backbehavior="history">
				<SubNavigator.Screen
					name="Dashboard"
					component={Dashboard}
					options={{
						title: 'Dashboard'
					}}
				/>
				<SubNavigator.Screen
					name="Customers"
					component={Customers}
					options={{
						title: 'Customers'
					}}
				/>
				<SubNavigator.Screen
					name="Reviews"
					component={Reviews}
					options={{
						title: 'Reviews'
					}}
				/>
				<SubNavigator.Screen
					name="Markets"
					component={Markets}
					options={{
						title: 'Markets'
					}}
				/>
				<SubNavigator.Screen
					name="Team"
					component={Team}
					options={{
						title: 'Team'
					}}
				/>
			</SubNavigator.Navigator>
		</View>
	);
}

export default Reports;
