import React, {useEffect, useState} from 'react';
import {View, ScrollView} from 'react-native';
import {useDebouncedCallback} from 'use-debounce';
import {connect} from 'react-redux';

import AppText from "../../../components/AppText";
import AppButton from "../../../components/AppButton";
import SurveyResponsesService from "../../../services/SurveyResponsesService";
import moment from "moment";
import {basicStyles} from "../../../styles/basic";
import ReportsSearchSection from "../../../components/ReportsSearchSection";
import PageContentContainer from "../../../components/PageContentContainer";
import ContentSection from "../../../components/ContentSection";
import SpreadSheet from "../../../components/SpreadSheet";
import {API_ROOT} from "../../../config";
import Pagination from "../../../components/Pagination";
import store from '../../../store';
import {setReviewResponse} from '../../../store/reviewResponse/reviewResponseActions';
import ChevronHorizontalBlackSvg from "../../../components/svgs/ChevronHorizontalBlackSvg";
import SurveysService from "../../../services/SurveysService";
import {formatDateAmPm} from "../../../utilities/methods";

function FluidReviews(props) {
    const surveyResponseId = props.route.params?.surveyResponseId;
    const [surveyResponses, setSurveyResponses] = useState([]);
    const perPage = 10;
    const [surveyCount, setSurveyCount] = useState(0);
    const [paginationRange, setPaginationRange] = useState([1]);
    const [disabledQuestions, setDisabledQuestions] = useState(['overall-rating','review','quality-rating','cost-rating','convenience-rating']);
    const [disabledQuestionsReady, setDisabledQuestionsReady] = useState(false);

    const {navigate} = props.navigation;

    let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : null;
    let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : null;
    let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
    let search = props.route?.params?.search ? props.route.params.search : '';

    const [getSurveyResponsesDebounce] = useDebouncedCallback(() => {
        SurveyResponsesService.getSurveyResponses(
            props.auth.organization.organization_id,
            search,
            null,
            null,
            true,
            startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            perPage,
            currentPage
        ).then(surveyResponsesData => {
            setSurveyResponses(surveyResponsesData.surveyResponses);

            if(surveyResponseId) {
                let surveyResonse = surveyResponsesData.surveyResponses?.find(el => el.survey_response_id == surveyResponseId);
                reviewResponseCallback(surveyResonse);
            }

            setDisabledSubQuestions(surveyResponsesData.surveyResponses);
            setSurveyCount(surveyResponsesData.surveyCount);
            setPaginationRange(surveyResponsesData.paginationRange);
        });
    });

    const reviewResponseCallback = async (data) => {
        var surveyResponseAnswer = data.surveyAnswers.find(el => el.survey_question_token = 'review' && el.survey_question_type_token == 'text-long');

        if(!surveyResponseAnswer?.survey_response_answer_id) {
            return;
        }

        var surveyReviewResponse = await SurveysService.getReviewResponse(surveyResponseAnswer.survey_response_answer_id);

        store.dispatch(setReviewResponse({
            active: true,
            review: data.review,
            response: (surveyReviewResponse?.survey_review_response_text ? surveyReviewResponse.survey_review_response_text : ''),
            userId: data.user_id,
            reviewerName: data['screen-name'],
            surveyResponseId: data.survey_response_id,
            surveyResponseAnswerId: surveyResponseAnswer.survey_response_answer_id,
            organizationTitle: props.auth?.organization?.organization_title,
            jobId: data.job_id,
            jobCustomerEmail: data.job_customer_email,
            jobCustomerPhone: data.job_customer_phone,
            displayJobButton: true,
            allowAiGeneratedResponse: data.organization_allow_ai_automatic_review_response === '1'
        }));
    };

    const setDisabledSubQuestions = (surveyResponses) => {
        let results = [...disabledQuestions];

        if(surveyResponses && surveyResponses.length > 0) {
            let surveyQuestions = surveyResponses[0]['surveyQuestions'];

            surveyQuestions.forEach((e) => {
                let questionIndex = results.indexOf(e.survey_question_token);
                if(questionIndex > -1) {
                    results.splice(questionIndex,1);
                }
            })
        }
        setDisabledQuestions(results);
        setDisabledQuestionsReady(true);
    };

    const columns = [
        {
            key: 'screen-name',
            exportName: 'screen-name',
            label: 'Screen Name',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['screen-name'],
            onChangeText() {
            },
        },
        {
            key: 'overall-rating',
            exportName: 'overall-rating',
            label: 'Overall Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['overall-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'review',
            exportName: 'review',
            label: 'Review',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['review'],
            onChangeText() {
            },
        },
        {
            key: 'quality-rating',
            exportName: 'quality-rating',
            label: 'Quality Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['quality-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'cost-rating',
            exportName: 'cost-rating',
            label: 'Cost Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['cost-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'convenience-rating',
            exportName: 'convenience-rating',
            label: 'Convenience Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['convenience-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'survey_response_create_timestamp',
            exportName: 'survey_response_create_timestamp',
            label: 'Survey Request Timestamp',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_create_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_viewed',
            exportName: 'survey_response_viewed',
            label: 'Survey Viewed',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_viewed_local']),
            onChangeText() {
            },
        },
        {
            key: 'store_front_address',
            exportName: 'store_front_address',
            label: 'Store Front Address',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['store_front_address'],
            onChangeText() {
            },
        },
        {
            key: 'user_email',
            exportName: 'user_email',
            label: 'Team Member Email',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['user_email'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_name',
            exportName: 'job_customer_name',
            label: 'Customer Name',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_name'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_address_locality',
            exportName: 'job_customer_address_locality',
            label: 'City',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_address_locality'],
            onChangeText() {
            },
        },
        {
            key: 'survey_response_responded_timestamp',
            exportName: 'survey_response_responded_timestamp',
            label: 'Completed Date',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_responded_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_email_opened_timestamp',
            exportName: 'survey_response_email_opened_timestamp',
            label: 'Email Opened',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_email_opened_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_email_permanent_failure_timestamp',
            exportName: 'survey_response_email_permanent_failure_timestamp',
            label: 'Email Failure',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_email_permanent_failure_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_email_permanent_failure_reason',
            exportName: 'survey_response_email_permanent_failure_reason',
            label: 'Email Failure Reason',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['survey_response_email_permanent_failure_reason'],
            onChangeText() {
            },
        },
        {
            key: 'survey_response_text_error_timestamp',
            exportName: 'survey_response_text_error_timestamp',
            label: 'Text Message Failure',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_text_error_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_text_error_reason',
            exportName: 'survey_response_text_error_reason',
            label: 'Text Failure Reason',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['survey_response_text_error_reason'],
            onChangeText() {
            },
        },
        {
            key: 'survey_review_response_text',
            exportName: 'survey_review_response_text',
            label: 'Company Response',
            type: 'text',
            disabled: true,
            valueExtractor: review => (review['surveyReviewResponse']?.length ? review['surveyReviewResponse'][0]['survey_review_response_text']  : ''),
            onChangeText() {
            },
        },
        {
            key: 'responding_team_member',
            exportName: 'responding_team_member',
            label: 'Responding Team Member',
            type: 'text',
            disabled: true,
            valueExtractor: review => (review['surveyReviewResponse']?.length ? (review['surveyReviewResponse'][0]['profile_first_name'] + ' ' + review['surveyReviewResponse'][0]['profile_last_name']) : ''),
            onChangeText() {
            },
        },
        {
            exportable: false,
            key: 'link',
            label: 'More',
            type: 'custom',
            valueExtractor(data) {
            },
            render(review) {
                return (
                    <AppButton
                        action={() => {
                            reviewResponseCallback(review)
                        }}
                        style={[
                            {
                                backgroundColor: '#0065DB',
                                padding: 10,
                                borderRadius: 5,
                            },
                            basicStyles.flexRow,
                            basicStyles.flexCenterContent,
                        ]}
                    >
                        <AppText style={{
                            color: '#FFFFFF',
                            marginRight: 10,
                        }}>
                            Respond
                        </AppText>
                        <ChevronHorizontalBlackSvg fill="#FFFFFF" direction="right"/>
                    </AppButton>
                );
            },
        }
    ];

    useEffect(() => {
        getSurveyResponsesDebounce();
    }, [props.auth.organization.organization_id, search, startDate, endDate, currentPage]);

    return (
            <>
                <ReportsSearchSection
                    onChangeSearch={(value) => {
                        navigate('FluidReviews', {
                            ...props.route?.params,
                            currentPage: 1,
                            search: value
                        });
                    }}
                    searchValue={search}
                    onChangeStartDate={(value) => {
                        navigate('FluidReviews', {
                            ...props.route?.params,
                            startDate: moment(value).format('MM-DD-YYYY'),
                            currentPage: 1
                        })
                    }}
                    startDateValue={startDate}
                    onChangeEndDate={(value) => {
                        navigate('FluidReviews', {
                            ...props.route?.params,
                            endDate: moment(value).format('MM-DD-YYYY'),
                            currentPage: 1
                        })
                    }}
                    endDateValue={endDate}
                />
                <PageContentContainer>
                    <ContentSection style={[
                        basicStyles.spreadSheetWrapper,
                        basicStyles.flexScale,
                    ]}>
                        {

                            surveyResponses.length && disabledQuestionsReady ?
                                <SpreadSheet
                                    rowCount={surveyCount}
                                    rows={surveyResponses}
                                    columns={columns}
                                    rowKeyExtractor="survey_response_id"
                                    exportUrl={API_ROOT + '/organizations/' + props.auth.organization.organization_id + '/survey-responses?completedOnly=1&search=' + encodeURIComponent(search) + '&completedAfterDate=' + encodeURIComponent(startDate ? moment(startDate).format('YYYY-MM-DD') : '') + '&completedBeforeDate=' + (encodeURIComponent(endDate ? moment(endDate).format('YYYY-MM-DD') : '')) + '&perPage=' + perPage + '&page=' + currentPage}
                                    exportTitle="reviews.csv"
                                    initialDisabledColumns={disabledQuestions}
                                />
                                : <View style={basicStyles.flexCenterContent}><AppText>No Records Found</AppText></View>
                        }
                    </ContentSection>
                </PageContentContainer>
                <Pagination screenName="FluidReviews" pageCount={Math.ceil(surveyCount / perPage)} pageIndex={currentPage} paginationRange={paginationRange}/>
            </>
    )
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(FluidReviews);
