import React, {useState, useEffect} from 'react';
import {View, ScrollView, ActivityIndicator} from "react-native";
import {connect} from 'react-redux';
import {BarChart, Grid} from 'react-native-svg-charts';
import moment from "moment";
import {Text} from "react-native-svg";
import EStyleSheet from 'react-native-extended-stylesheet';

import PageTop from "../../components/PageTop";
import {basicStyles} from "../../styles/basic";
import Avatar from "../../components/Avatar";
import {AVATAR_DEFAULT, AVATAR_PATH_ORG} from "../../config";
import AppText from "../../components/AppText";
import {textStyles} from "../../styles/text";
import PageContentContainer from "../../components/PageContentContainer";
import ContentSection from "../../components/ContentSection";
import AppBarChart from "../../components/AppBarChart";
import OrganizationsService from "../../services/OrganizationsService";


function Dashboard(props) {
    const [state, setState] = useState({
        totalJobs: 0,
        totalMarkets: 0,
        totalCompletedSurveys: 0,
        jobsPostedPerDay: 0,
        totalSurveys: 0,
        surveyCompletionRate: 0,
        jobsFromLast2Weeks: [],
        surveysFromLast2Weeks: [],
        reviewsVelocityData: [],
        loadingData: true
    });

    const {organization} = props.auth;

    useEffect(() => {
        const getData = async () => {
            const data = await OrganizationsService.getDashboardReportData(organization.organization_id);
            setState(prevState => {
                return Object.assign({}, prevState, data, {loadingData: false})
            });
        };

        getData();
    }, [organization.organization_id]);

    let jobsPerDay = [];
    let currentMoment = moment().subtract(13, 'days');
    let endMoment = moment().add(1, 'days');

    while (currentMoment.isBefore(endMoment, 'day')) {
        let jobCount = 0
        for (let job of state.jobsFromLast2Weeks) {
            if (moment(job.job_timestamp).isSame(currentMoment.format('YYYY-MM-DD'), 'days')) {
                jobCount++;
            }
        }

        jobsPerDay.push({
            label: currentMoment.format('ddd'),
            value: jobCount
        });

        currentMoment.add(1, 'days');
    }

    let surveysPerDay = [];
    currentMoment = moment().subtract(13, 'days');
    while (currentMoment.isBefore(endMoment, 'day')) {
        let surveysCount = 0;
        for (let survey of state.surveysFromLast2Weeks) {
            if (moment(survey.survey_response_create_timestamp).isSame(currentMoment.format('YYYY-MM-DD'), 'days')) {
                surveysCount++;
            }
        }

        surveysPerDay.push({
            label: currentMoment.format('ddd'),
            value: surveysCount
        });

        currentMoment.add(1, 'days');
    }

    let reviewsVelocityDataFormatted = [
        {
            data: [],
            svg: {
                fill: '#578eba' //jobs count
            }
        },
        {
            data: [],
            svg: {
                fill: '#2eafd4' //reviews sent count
            }
        },
        {
            data: [],
            svg: {
                fill: '#7cc37d' //reviews completed count
            }
        },
    ];
    let reviewsVelocityLabels = [];
    for (let data of state.reviewsVelocityData) {
        if(data.week) {
            reviewsVelocityDataFormatted[0].data.push({value: parseInt(data.jobs_count)});
            reviewsVelocityDataFormatted[1].data.push({value: parseInt(data.surveys_sent_count)});
            reviewsVelocityDataFormatted[2].data.push({value: parseInt(data.surveys_completed_count)});

            reviewsVelocityLabels.push(moment(data.start_date).format('MM/DD/YY'));
        }
    }

    const maxValue = Math.max(...reviewsVelocityDataFormatted[0].data.concat(reviewsVelocityDataFormatted[1].data).concat(reviewsVelocityDataFormatted[2].data).map(({value}) => value))
    const CUT_OFF = maxValue - 10;

    const Labels = ({x, y, bandwidth, data}) => {
        let labels = (
            data.map((dataList, dataListIndex) => {
                let xModifier;
                switch (dataListIndex) {
                    case 0:
                        xModifier = 0 - (bandwidth * .333);
                        break;
                    case 1:
                        xModifier = 0;
                        break;
                    case 2:
                        xModifier = (bandwidth * .333);
                        break;
                }
                return dataList.data.map((item, index) => {
                    const value = item.value;
                    return (
                        <Text
                            key={index}
                            x={(x(index) + (bandwidth / 2)) + xModifier}
                            y={value < CUT_OFF ? y(value) - 10 : y(value) + 15}
                            fontSize={14}
                            fill={value >= CUT_OFF ? 'white' : 'black'}
                            alignmentBaseline={'middle'}
                            textAnchor={'middle'}
                            style={{fontSize: 14, fontFamily: 'SourceSansPro-SemiBold'}}
                        >
                            {value > 0 ? value : ''}
                        </Text>
                    );
                })
            })
        )

        return [].concat.apply([], labels)
    };

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale}>
            <PageTop style={[
                basicStyles.flexRow,
                basicStyles.alignContentCenter,
            ]}>
                {
                    organization ?
                        <>
                            <Avatar
                                source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                                width={66}
                                style={{marginRight: 20}}
                            />
                            <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                                {organization.organization_title}
                            </AppText>
                        </>
                        :
                        <>
                            <Avatar
                                source={{uri: AVATAR_DEFAULT}}
                                width={66}
                                style={{marginRight: 20}}
                            />
                            <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                                All Organizations
                            </AppText>
                        </>
                }
                {
                    state.loadingData ?
                        <View style={[basicStyles.flexRow, basicStyles.flexCenterContent]}>
                            <Text style={{marginLeft: 10, marginRight: 6}}>
                                <ActivityIndicator size="large" color="#467AFF"/>
                            </Text>
                            <AppText>Gathering Data</AppText>
                        </View>
                        :
                        null
                }
            </PageTop>
            <PageContentContainer>
                <ContentSection style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    basicStyles.flexWrap,
                    {
                        paddingRight: 15,
                        paddingLeft: 15,
                        paddingTop: 30,
                        paddingBottom: 15,
                        marginBottom: 60,
                    }
                ]}>
                    <View style={styles.dataSectionWrapper}>
                        <View style={styles.dataSection}>
                            <AppText style={styles.dataValue}>
                                {state.totalJobs}
                            </AppText>
                            <AppText style={styles.dataLabel}>
                                Total Jobs Posted
                            </AppText>
                        </View>
                    </View>
                    <View style={styles.dataSectionWrapper}>
                        <View style={styles.dataSection}>
                            <AppText style={styles.dataValue}>
                                {state.totalMarkets}
                            </AppText>
                            <AppText style={styles.dataLabel}>
                                Total Cities Served
                            </AppText>
                        </View>
                    </View>
                    <View style={styles.dataSectionWrapper}>
                        <View style={styles.dataSection}>
                            <AppText style={styles.dataValue}>
                                {state.totalCompletedSurveys}
                            </AppText>
                            <AppText style={styles.dataLabel}>
                                Completed Reviews
                            </AppText>
                        </View>
                    </View>
                    <View style={styles.dataSectionWrapper}>
                        <View style={styles.dataSection}>
                            <AppText style={styles.dataValue}>
                                {state.jobsPostedPerDay}
                            </AppText>
                            <AppText style={styles.dataLabel}>
                                Jobs Posted Per Day
                            </AppText>
                        </View>
                    </View>
                    <View style={styles.dataSectionWrapper}>
                        <View style={styles.dataSection}>
                            <AppText style={styles.dataValue}>
                                {state.totalSurveys}
                            </AppText>
                            <AppText style={styles.dataLabel}>
                                Total Surveys Sent
                            </AppText>
                        </View>
                    </View>
                    <View style={styles.dataSectionWrapper}>
                        <View style={styles.dataSection}>
                            <AppText style={styles.dataValue}>
                                {parseFloat(state.surveyCompletionRate) * 100}%
                            </AppText>
                            <AppText style={styles.dataLabel}>
                                Review Completion Percentage
                            </AppText>
                        </View>
                    </View>
                </ContentSection>
                <View style={[
                    basicStyles.flexRow,
                    styles.topGraphs
                ]}>
                    <View style={[
                        {
                            paddingRight: 15,
                            paddingBottom: 30,
                        },
                        styles.topGraph
                    ]}>
                        <View style={[
                            basicStyles.flexRow,
                            basicStyles.alignContentCenter,
                        ]}>
                            <AppText style={styles.barChartHeading}>
                                Job Count
                            </AppText>
                            <View style={styles.barChartHeadingDivider}/>
                            <AppText style={styles.barChartSubHeading}>
                                Last 14 Days
                            </AppText>
                        </View>
                        <AppBarChart
                            data={jobsPerDay}
                            labelPrecision={0}
                            preBarLabel=""
                        />
                    </View>
                    <View style={[
                        {
                            paddingLeft: 15
                        },
                        styles.topGraph
                    ]}>
                        <View style={[
                            basicStyles.flexRow,
                            basicStyles.alignContentCenter,
                        ]}>
                            <AppText style={styles.barChartHeading}>
                                Surveys Sent
                            </AppText>
                            <View style={styles.barChartHeadingDivider}/>
                            <AppText style={styles.barChartSubHeading}>
                                Last 14 Days
                            </AppText>
                        </View>
                        <AppBarChart
                            data={surveysPerDay}
                            labelPrecision={0}
                            preBarLabel=""
                        />
                    </View>
                </View>
                <View>
                    <View style={[
                        basicStyles.flexRow,
                        basicStyles.justifyContentSpaceBetween,
                        basicStyles.alignContentCenter,
                        styles.reviewVelocity
                    ]}>
                        <View style={[
                            basicStyles.flexRow,
                            basicStyles.alignContentCenter,
                            styles.reviewVelocityTitle
                        ]}>
                            <AppText style={styles.barChartHeading}>
                                Weekly Review Velocity
                            </AppText>
                            <View style={styles.barChartHeadingDivider}/>
                            <AppText style={styles.barChartSubHeading}>
                                Last 5 Months
                            </AppText>
                        </View>
                        <View style={[basicStyles.flexRow, styles.reviewVelocityLegend]}>
                            <View style={{backgroundColor: '#578eba', padding: 5, margin: 5}}>
                                <AppText style={{color: '#FFFFFF'}}>Job Count</AppText>
                            </View>
                            <View style={{backgroundColor: '#2eafd4', padding: 5, margin: 5}}>
                                <AppText style={{color: '#FFFFFF'}}>Surveys Sent</AppText>
                            </View>
                            <View style={{backgroundColor: '#7cc37d', padding: 5, margin: 5}}>
                                <AppText style={{color: '#FFFFFF'}}>Completed Reviews</AppText>
                            </View>
                        </View>
                    </View>
                    <ScrollView
                        horizontal={true}
                        style={[styles.grid, styles.gridInversion]}
                        contentContainerStyle={{minWidth: '100%'}}
                        showsHorizontalScrollIndicator={true}
                    >
                        <View style={[styles.customChartWrapper, styles.gridInversion]}>
                            <BarChart
                                style={styles.customChart}
                                data={reviewsVelocityDataFormatted}
                                contentInset={{top: 10, bottom: 10}}
                                yAccessor={({item}) => item.value}
                                gridMin={0}
                            >
                                <Grid direction={Grid.Direction.HORIZONTAL}/>
                                <Labels/>
                            </BarChart>
                            <View style={styles.customChartXAxis}>
                                {
                                    reviewsVelocityLabels.map(label => {
                                        return (
                                            <View key={label} style={{width: 51}}>
                                                <AppText style={{fontSize: 12}}>
                                                    {label}
                                                </AppText>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </PageContentContainer>
        </ScrollView>
    )
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(Dashboard);

const styles = EStyleSheet.create({
    dataSectionWrapper: {
        width: '33.333%',
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 15
    },
    dataSection: {
        borderBottomWidth: 1,
        borderBottomColor: '#D9E8F8',
        paddingBottom: 8,
    },
    dataValue: {
        fontSize: 20
    },
    dataLabel: {
        color: '#9BA8CA',
        fontSize: 16,
    },
    barChartHeading: {
        fontSize: 20,
        fontFamily: 'SourceSansPro-SemiBold',
    },
    barChartSubHeading: {
        fontSize: 20,
        fontFamily: 'SourceSansPro-Light'
    },
    barChartHeadingDivider: {
        width: 1,
        height: 20,
        backgroundColor: '#D9E8F8',
        marginRight: 10,
        marginLeft: 10,
    },

    customChartWrapper: {
        height: 325,
        paddingVertical: 16,
        width: '100%'
    },
    customChart: {
        flex: 1,
        width: '100%'
    },
    customChartXAxis: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    topGraphs: {
        marginBottom: 30
    },
    topGraph: {
        width: '50%',
    },
    reviewVelocity: {
        paddingBottom: 30,
    },
    grid: {
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 5,
        minWidth: '100%',
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
    },
    gridInversion: {
        transform:[{rotateX:'180deg'}]
    },
    '@media (max-width: 1300)': {},
    '@media (max-width: 900)': {
        topGraphs: {
            flexDirection: 'column',
            marginBottom: 30,
        },
        topGraph: {
            width: '100%',
            paddingRight: 0,
            paddingLeft: 0,
        }
    },
    '@media (max-width: 850)': {
        reviewVelocity: {
            flexDirection: 'column',
            alignItems: 'left'
        },
        reviewVelocityTitle: {
            marginLeft: 5,
        }
    },
    '@media (max-width: 600)': {
        dataSectionWrapper: {
            width: '50%'
        }
    },
    '@media (max-width: 400)': {
        dataSectionWrapper: {
            textAlign: 'center'
        },
        reviewVelocityTitle: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        barChartHeadingDivider: {
            display: 'none',
        },
        reviewVelocityLegend: {
            flexDirection: 'column',
        }
    }
});
