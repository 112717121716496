import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {ActivityIndicator, Animated, ScrollView, TouchableOpacity, View} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import {CancelToken} from "axios";
import {connect} from 'react-redux';

import CustomUploadsService from "../../services/CustomUploadsService";
import ContentSection from "../../components/ContentSection";
import PageContentContainer from '../../components/PageContentContainer';
import {basicStyles} from "../../styles/basic";
import AppText from "../../components/AppText";
import {textStyles} from "../../styles/text";
import AppButton from "../../components/AppButton";
import {formatDate, generateUuid, objectFlip} from "../../utilities/methods";
import {setWarning} from "../../store/warning/warningActions";
import {bindActionCreators} from "redux";
import UploadOverlayHidden from "../../components/UploadOverlayHidden";
import AppTextInput from "../../components/AppTextInput";
import Modal from "../../components/Modal";
import {Col, Grid, Row} from "react-native-easy-grid";


function CustomUploads(props) {
	const [customUploads, setCustomUploads] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [loadingUploadedFile, setLoadingUploadedFile] = useState(true);
	const [uploadContent, setUploadContent] = useState([]);
	const [viewUploadContent, setViewUploadContent] = useState(null);
	const [isSending, setIsSending] = useState(false);

	const [state, setState] = useState({
		columnHeadContentSizes: {},
	});

	const updateDataRequestSources = useRef([]);

	const updateCustomUpload = useCallback((index, prop, value) => {
		setCustomUploads(current => {
			let customUploads = [...current];
			customUploads[index][prop] = value;

			return customUploads;
		});
	}, [customUploads, setCustomUploads])


	const organizationId = props.auth.organization.organization_id;

	const saveCustomUpload = useCallback(async (customUploadIndex) => {
		let customUpload;
		if (!customUploads[customUploadIndex].custom_upload_id) {
			customUpload = await CustomUploadsService.insertCustomUpload(organizationId, customUploads[customUploadIndex]);
		} else {
			if (updateDataRequestSources.current[customUploadIndex]) {
				updateDataRequestSources.current[customUploadIndex].cancel('Request Canceled');
			}
			updateDataRequestSources.current[customUploadIndex] = CancelToken.source();
			customUpload = await CustomUploadsService.updateCustomUpload(organizationId, customUploads[customUploadIndex].custom_upload_id, customUploads[customUploadIndex], updateDataRequestSources.current[customUploadIndex].token);
		}

		setCustomUploads(current => {
			const customUploads = [...current];

			customUpload.editing = false;

			customUploads.splice(customUploadIndex, 1, customUpload)

			return customUploads;
		});
	}, [organizationId, customUploads]);

	const handleCSVUpload = useCallback(async (results, customUploadIndex, customUploadId) => {
		let file = results[0];
		let formData = new FormData();
		formData.append('csv', file);

		let customUpload;
		try {
			customUpload = await CustomUploadsService.uploadCSV(organizationId, customUploadId, formData);
			setCustomUploads(current => {
				const customUploads = [...current];
				customUploads.splice(customUploadIndex, 1, customUpload)
				return customUploads;
			});
			setViewUploadContent(customUploadIndex);
		} catch (error) {
			console.log('uploadError: ', error);
		}
	}, [organizationId]);

	const sendCustomUploadEmails = useCallback(async (customUploadIndex) => {
		if(isSending)
			return;

		let customUpload;
		setIsSending(true);
		try {
			customUpload = await CustomUploadsService.sendCustomUpload(organizationId, customUploads[customUploadIndex].custom_upload_id);
			setCustomUploads(current => {
				const customUploads = [...current];
				customUploads.splice(customUploadIndex, 1, customUpload)
				return customUploads;
			});
			setIsSending(false);
		} catch (error) {
			console.log('Send Custom Upload Error: ', error);
			setIsSending(false);
		}
		handleCloseModal();
	}, [organizationId, customUploads, isSending]);

	// const deleteCustomUpload = useCallback(async (customUploadIndex) => {
	// 	if (customUploads[customUploadIndex].custom_upload_id) {
	// 		await CustomUploadsService.deleteCustomUpload(organizationId, customUploads[customUploadIndex].custom_upload_id)
	// 	}
	//
	// 	setCustomUploads((current) => {
	// 		const customUploads = [...current];
	//
	// 		customUploads.splice(customUploadIndex, 1);
	//
	// 		return customUploads;
	// 	});
	//
	// }, [organizationId, customUploads]);

	const getUploadContent = async (customUploadIndex) => {
		let data = await CustomUploadsService.getCustomUploadCSVContent(organizationId, customUploads[customUploadIndex].custom_upload_id);
		setUploadContent(data);
		setLoadingUploadedFile(false);
	};

	const handleCloseModal = useCallback(() => {
		setViewUploadContent(null);
	});


	useEffect(() => {
		CustomUploadsService.getCustomUploads(organizationId)
			.then(customUploads => {
				setCustomUploads(current => [...current, ...customUploads]);
				setIsLoading(false);
			});
	}, [organizationId]);

	useEffect(() => {
		if (viewUploadContent !== null) {
			setLoadingUploadedFile(true);
			getUploadContent(viewUploadContent);
		} else {
			setUploadContent([]);
		}
	}, [viewUploadContent]);

	return (
		<>
			<ScrollView contentContainerStyle={basicStyles.flexScale} >
				<PageContentContainer>
					<View
						style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter, {marginBottom: 25}]}>
						<AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
							Custom Uploads
						</AppText>
						<AppButton
							label="Add Custom Upload"
							action={() => {
								setCustomUploads(current => {
									return [
										{temp_key: generateUuid(), editing: true},
										...current,
									];
								})
							}}
						/>
					</View>
					{
						customUploads.length > 0 ?
							customUploads.map((customUpload, index) => {
								if (customUpload.editing === true) {
									// Allow textarea to resize up to 8 lines with a minimum of 4
									let numRows = customUpload['custom_upload_body']?.split(/\r\n|\r|\n/).length ?? 0;
									numRows = Math.min(Math.max(numRows, 4), 8);
									return (
										<ContentSection
											key={customUpload.custom_upload_id || customUpload.temp_key}
											style={[
											basicStyles.inputContainerPaddingVertical,
											basicStyles.inputContainerPaddingHorizontal,
											styles.customUploadContainer
										]}>

											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentCenter,
											]}>
												<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
														<AppTextInput
															label="Custom Upload Name"
															value={customUpload.custom_upload_name}
															onChangeText={value => {
																updateCustomUpload(index, 'custom_upload_name', value);
															}}
														/>
												</View>
												<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
													<AppTextInput
														label="To Email"
														value={customUpload.custom_upload_to_email}
														onChangeText={value => {
															updateCustomUpload(index, 'custom_upload_to_email', value);
														}}
													/>
												</View>
											</View>
											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentCenter
											]}>
												<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
													<AppTextInput
														label="From Email"
														value={customUpload.custom_upload_from_email}
														onChangeText={value => {
															updateCustomUpload(index, 'custom_upload_from_email', value);
														}}
													/>
												</View>
												<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
													<AppTextInput
														label="From Name"
														value={customUpload.custom_upload_from_name}
														onChangeText={value => {
															updateCustomUpload(index, 'custom_upload_from_name', value);
														}}
													/>
												</View>
											</View>
											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentCenter
											]}>
												<View style={[basicStyles.inputWrapper, {width: '100%'}]}>
													<AppTextInput
														label="Email Subject"
														value={customUpload.custom_upload_subject}
														onChangeText={value => {
															updateCustomUpload(index, 'custom_upload_subject', value);
														}}
													/>
												</View>
											</View>
											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentCenter
											]}>
												<View style={[basicStyles.inputWrapper, {width: '100%'}]}>
													<AppTextInput
														wrapperStyle={[styles.emailBodyInputWrapper, {height: 'auto'}]}
														inputStyle={{minHeight: 57, padding: 15, paddingTop: 38, overflowY: 'auto'}}
														multiline={true}
														lines={numRows}
														rows={numRows}
														numberOfLines={numRows}
														label="Email Body"
														labelStyle={[styles.emailBodyLabelStyle, {width: "calc(100% - 15px)"}]}
														value={customUpload.custom_upload_body}
														onChangeText={value => {
															updateCustomUpload(index, 'custom_upload_body', value);
														}}
													/>
												</View>
											</View>
											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentSpaceBetween,
												basicStyles.flexWrap
											]}>
												<AppButton
													label="Close"
													theme="transBlue"
													action={() => {
														updateCustomUpload(index, 'editing', false);
													}}
												/>

												<AppButton
													label="Save"
													action={() => {
														saveCustomUpload(index);
													}}
												/>
											</View>
										</ContentSection>
									);
								} else {

									return (
										<ContentSection
											key={customUpload.custom_upload_id || customUpload.temp_key}
											style={[
												basicStyles.inputContainerPaddingVertical,
												styles.customUploadContainer
											]}>
											<View style={[
												basicStyles.flexRow,
												basicStyles.justifyContentSpaceBetween,
												styles.customUploadRow
											]}>

												<View style={[basicStyles.alignContentCenter, styles.customUploadRowFirstItem]}>
													<AppText>{customUpload.custom_upload_name}</AppText>
												</View>
												<View style={[styles.customUploadRowItem]}>
													<AppButton
														label="Upload CSV"
													>
														<UploadOverlayHidden
															callback={(results) => {
																handleCSVUpload(results, index, customUpload.custom_upload_id);
															}}
														/>
													</AppButton>
												</View>
												<View style={[styles.customUploadRowItem]}>
													<AppText>Upload By</AppText>
													<AppText
														style={{color: 'rgb(155, 168, 202)'}}>{customUpload.user_display_name}</AppText>
												</View>
												<View style={[styles.customUploadRowItem]}>
													<AppText>Last Upload Sent</AppText>
													<AppText
														style={{color: 'rgb(155, 168, 202)'}}>{customUpload.custom_upload_timestamp ? formatDate(customUpload.custom_upload_timestamp) : 'N/A'}</AppText>
												</View>
												<AppButton
													style={[styles.customUploadRowItem]}
													label="View Last Upload"
													action={() => {
														setViewUploadContent(index);
													}}
												/>
												<AppButton
													style={[styles.customUploadRowItem]}
													label="Edit"
													action={() => {
														updateCustomUpload(index, 'editing', true);
													}}
												/>
											</View>
										</ContentSection>
									);
								}
							})
							:
							isLoading ?
								<View style={[basicStyles.flexRow, basicStyles.flexCenterContent]}>
									<AppText style={{marginLeft: 10, marginRight: 6}}>
										<ActivityIndicator size="large" color="#467AFF"/>
									</AppText>
									<AppText>Gathering Data</AppText>
								</View>
								:
								(
									<ContentSection style={[
										basicStyles.inputContainerPaddingVertical,
										basicStyles.inputContainerPaddingHorizontal,
										basicStyles.alignContentCenter,
										basicStyles.justifyContentCenter
									]}>
										<AppText>
											No Custom Uploads Currently Configured
										</AppText>
									</ContentSection>
								)
					}
				</PageContentContainer>
			</ScrollView>
			<Modal
				active={viewUploadContent !== null}
				onClose={handleCloseModal}>
				<ContentSection
					style={[styles.contentWrapper]}
					onClick={e => {
						e.stopPropagation();
					}}
				>
					<ScrollView>
						<AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
							Custom Upload Content
						</AppText>
						{
							viewUploadContent !== null ?
								<View>
									<View style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween]}>
										<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
											<AppText style={[textStyles.semiBold]}>
												To Email:
											</AppText>
											<AppText>
												{customUploads[viewUploadContent].custom_upload_to_email}
											</AppText>
										</View>
										<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
											<AppText style={[textStyles.semiBold]}>
												From Email:
											</AppText>
											<AppText>
												{customUploads[viewUploadContent].custom_upload_from_email}
											</AppText>
										</View>
										<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
											<AppText style={[textStyles.semiBold]}>
												From Name:
											</AppText>
											<AppText>
												{customUploads[viewUploadContent].custom_upload_from_name}
											</AppText>
										</View>
									</View>
									<View style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween]}>
										<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
											<AppText style={[textStyles.semiBold]}>
												Subject:
											</AppText>
											<AppText>
												{customUploads[viewUploadContent].custom_upload_subject}
											</AppText>
										</View>
									</View>
									<View style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween]}>
										<View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
											<AppText style={[textStyles.semiBold]}>
												Body:
											</AppText>
											<AppText>
												{customUploads[viewUploadContent].custom_upload_body}
											</AppText>
										</View>
									</View>
								</View>
								: null
						}
						{
							loadingUploadedFile ?
								<View style={[basicStyles.flexRow, basicStyles.flexCenterContent]}>
									<AppText style={{marginLeft: 10, marginRight: 6}}>
										<ActivityIndicator size="large" color="#467AFF"/>
									</AppText>
									<AppText>Gathering Data</AppText>
								</View>
								:
								<ScrollView style={[styles.outerGrid]}>
									<ScrollView
										style={[styles.grid, styles.gridInversion]}
										horizontal={true}
										showsHorizontalScrollIndicator={true}
									>
										<View style={[styles.gridInversion]}>
											<Grid>
												{
													uploadContent.map((row, rowIndex) => {
														if(rowIndex === 0) {
															return (
																<Row
																	key={'row-' + rowIndex}
																	style={[
																		styles.row,
																		styles.rowHead,
																		{
																			zIndex: 1,
																			elevation: 1,
																		},
																	]}
																>
																	{
																		row.map((column, columnIndex) => {
																			return (
																				<Col
																					key={rowIndex + '-' + columnIndex}
																					numberOfLines={1}
																					style={[
																						styles.cell,
																						styles.cellHead,
																						(columnIndex === row.length - 1 ? styles.cellLast : {}),
																						{
																							whiteSpace: 'nowrap',
																							minWidth: state.columnHeadContentSizes[columnIndex] + 70
																						}
																					]}
																				>
																					<View
																						style={[styles.cellText]} numberOfLines={1}
																						onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
																							setState(prevState => {
																								return Object.assign({}, prevState, {columnHeadContentSizes: {...prevState.columnHeadContentSizes, [columnIndex]: width}});
																							});
																						}}
																					>
																						<AppText style={styles.columnHeadLabel} numberOfLines={1}>
																							{column}
																						</AppText>
																					</View>
																				</Col>
																			);
																		})
																	}
																</Row>
															);
														} else {
															return (
																<Row
																	key={'row-' + rowIndex}
																	style={[
																		styles.row,
																		styles.rowBody,
																		(rowIndex % 2 !== 1 ? styles.rowOdd : {}),
																		(rowIndex === uploadContent.length - 1 ? styles.rowLast : {}),

																	]}
																>
																	{
																		row.map((column, columnIndex) => {
																			return (
																				<Col
																					key={rowIndex + '-' + columnIndex}
																					style={
																						[
																							styles.cell,
																							styles.cellBody,
																							(columnIndex === row.length - 1 ? styles.cellLast : {}),
																							{minWidth: state.columnHeadContentSizes[columnIndex] + 70},

																						]
																					}
																				>
																					<View
																						style={[styles.cellText]} numberOfLines={1}
																					>
																						<AppText style={styles.columnHeadLabel}>
																							{column}
																						</AppText>
																					</View>
																				</Col>
																			);
																		})
																	}
																</Row>
															);
														}
													})
												}
											</Grid>
										</View>
									</ScrollView>
								</ScrollView>
						}
					</ScrollView>
					<View
						style={[
							basicStyles.flexRow,
							styles.uploadContentButtons
						]}
					>
						<AppButton action={() => handleCloseModal()} theme="transBlue" label="Close"/>
						{
							isSending ?
								<View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
									<AppText style={{marginLeft: 10, marginRight: 6}}>
										<ActivityIndicator size="large" color="#467AFF"/>
									</AppText>
									<AppText>Sending</AppText>
								</View>
								:
								<AppButton action={() => sendCustomUploadEmails(viewUploadContent)} theme="blue" label="Send"/>
						}
					</View>
				</ContentSection>
			</Modal>
		</>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		setWarning,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CustomUploads)

const styles = EStyleSheet.create({
	heading: {
		fontFamily: 'SourceSansPro-Bold',
		paddingLeft: 22,
		fontSize: 16,
		marginBottom: 10
	},
	subHeading: {
		fontFamily: 'SourceSansPro-Bold',
		paddingLeft: 22
	},
	toggleWrapper: {
		minWidth: 130
	},
	inputWrapper: {
		flex: 1
	},
	deleteIcon: {
		position: 'absolute',
		top: 15,
		right: 15
	},
	contentSection: {
		flexDirection: 'row',
		marginBottom: 50
	},
	customUploadContainer: {
		paddingLeft: 30,
		paddingRight: 30,
		justifyContent: 'space-between',
		marginBottom: 10
	},
	customUploadRowFirstItem: {
		width: '15%',
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	uploadContentRow: {
		paddingLeft: 10,
		paddingRight: 10
	},
	customUploadContentItem: {
		fontSize: 15,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 5,
		paddingBottom: 5,
		flex: 1
	},
	'@media (max-width: 720)': {
		contentSection: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		contentItem: {
			width: '100%',
			borderBottomWidth: 1,
			borderBottomColor: '#E2E9F4'
		}
	},
	'@media (max-width: 750)': {
		headerContainer: {
			width: '100%',
		},
		customUploadContainer: {
			flexDirection: 'column',

		},
		customUploadRow: {
			flexDirection: 'column'
		},
		customUploadRowItem: {
			marginTop: 10,
		}
	},
	contentWrapper: {
		padding: 32,
		maxWidth: '90vw',
		maxHeight: '90vh'

	},
	columnHeading: {
		backgroundColor: '#f8fafe',
	},
	cell: {
		justifyContent: 'center',
		borderRightWidth: 1,
		borderRightColor: '#E2E9F4',
	},
	cellHead: {
		backgroundColor: '#F8FAFD',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingRight: 30,
	},
	rowOdd: {
		backgroundColor: '#F8FAFD',
	},
	columnHeadText: {
		fontFamily: 'SourceSansPro-SemiBold',
		fontSize: 14,
		whiteSpace: 'nowrap',
	},
	outerGrid: {
		maxHeight: 400,
		overflowY: 'auto',
		borderColor: '#E2E9F4',
		borderBottomWidth: 1,
		borderTopWidth: 1,
		maxWidth: '100%',
	},
	grid: {
		borderLeftWidth: 1,
		borderRightWidth: 1,
		borderColor: '#E2E9F4',
		minWidth: '100%',
	},
	gridInversion: {
		transform:[{rotateX:'180deg'}]
	},
	columnFilterSectionPadding: {
		paddingLeft: 16,
		paddingRight: 16,
	},
	columnHeadLabel: {
		whiteSpace: 'nowrap',
	},
	row: {
		flexGrow: 1,
		flexShrink: 1,
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
	},
	rowHead: {
		height: 40,
		flexBasis: 40,
	},
	rowBody: {
		height: 40,
		flexBasis: 40,
	},
	cellLast: {
		borderRightWidth: 0,
	},
	rowLast: {
		borderBottomWidth: 0,
	},
	spreadSheetContainer: {
		marginBottom: 40,
		zIndex: 1,
		elevation: 1,
		maxHeight: 600
	},
	cellBody: {
		justifyContent: 'center',
	},
	cellText: {
		paddingRight: 20,
		paddingLeft: 20,
		flexDirection: 'row',
		whiteSpace: 'nowrap',
		overflow: 'clip'
	},
	uploadContentButtons: {
		justifyContent: 'space-evenly',
		marginTop: 30
	},
	emailBodyInputWrapper: {
		padding: 0,
	},
	emailBodyLabelStyle: {
		paddingLeft: 15,
		paddingTop: 15,
		position: 'absolute',
		backgroundColor: 'white'
	}
});
