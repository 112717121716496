import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import AppText from "../../../components/AppText";
import {useDebouncedCallback} from "use-debounce";
import OrganizationsService from "../../../services/OrganizationsService";
import ContentSection from "../../../components/ContentSection";
import {basicStyles} from "../../../styles/basic";
import Avatar from "../../../components/Avatar";
import {API_ASSETS, THIRD_PARTY_REVIEW_ICONS} from "../../../config";
import {formatDate} from "../../../utilities/methods";
import StarRangeInput from "../../../components/StarsRangeInput";
import TouchView from "../../../components/TouchView";
import CollapseSvg from "../../../components/svgs/CollapseSvg";
import ExpandSvg from "../../../components/svgs/ExpandSvg";
import PageContentContainer from "../../../components/PageContentContainer";
import {connect} from "react-redux";
import ReportsSearchSection from "../../../components/ReportsSearchSection";
import moment from "moment";
import Pagination from "../../../components/Pagination";
import AppButton from "../../../components/AppButton";
import store from "../../../store";
import {setReviewResponse} from "../../../store/reviewResponse/reviewResponseActions";

function ThirdPartyReviews(props) {
    const [state, setState] = useState({
        thirdPartyReviews: []
    });
    const [reviewTextActiveIndexes, setReviewTextActiveIndexes] = useState([]);
    const perPage = 10;

    const {navigate} = props.navigation;

    let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : null;
    let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : null;
    let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
    let search = props.route?.params?.search ? props.route.params.search : '';

    const [geThirdPartyReviewsDebounce] = useDebouncedCallback(() => {
        OrganizationsService.getThirdPartyReviews(
            props.auth.organization.organization_id,
            search,
            startDate,
            endDate,
            perPage,
            currentPage
        ).then(thirdPartyReviewsData => {
            setState({
                thirdPartyReviews: thirdPartyReviewsData.thirdPartyReviews,
                pageCount: thirdPartyReviewsData.pageCount
            });
        })
    });

    function toggleReviewTextActive(index) {
        let currentIndex = reviewTextActiveIndexes.indexOf(index);

        if (currentIndex >= 0) {
            setReviewTextActiveIndexes(prevState => {
                let indexes = [...prevState];
                indexes.splice(currentIndex, 1);

                return indexes;
            })
        } else {
            setReviewTextActiveIndexes(prevState => {
                let indexes = [...prevState];
                indexes.push(index);

                return indexes;
            })
        }
    }

    function reviewTextActive(index) {
        return reviewTextActiveIndexes.indexOf(index) >= 0;
    }

    function openReviewRespond(data) {
        store.dispatch(setReviewResponse({
            active: true,
            review: data.third_party_review_text,
            reviewerName: data.third_party_review_author,
            organizationTitle: props.auth?.organization?.organization_title,
            allowAiGeneratedResponse: false
        }));
    }

    useEffect(() => {
        geThirdPartyReviewsDebounce();
    }, [props.auth.organization.organization_id, search, startDate, endDate, currentPage]);

    return (
        <>
            <ReportsSearchSection
                onChangeSearch={(value) => {
                    navigate('ThirdPartyReviews', {
                        ...props.route?.params,
                        currentPage: 1,
                        search: value
                    });
                }}
                searchValue={search}
                onChangeStartDate={(value) => {
                    navigate('ThirdPartyReviews', {
                        ...props.route?.params,
                        startDate: moment(value).format('MM-DD-YYYY'),
                        currentPage: 1
                    })
                }}
                startDateValue={startDate}
                onChangeEndDate={(value) => {
                    navigate('ThirdPartyReviews', {
                        ...props.route?.params,
                        endDate: moment(value).format('MM-DD-YYYY'),
                        currentPage: 1
                    })
                }}
                endDateValue={endDate}
            />
            <PageContentContainer>
                {
                    state.thirdPartyReviews.length ?
                        state.thirdPartyReviews.map((thirdPartyReview, index) => {
                            return (
                                <ContentSection
                                    key={thirdPartyReview.third_party_review_id}
                                    style={[
                                        basicStyles.spreadSheetWrapper,
                                        basicStyles.flexScale,
                                        {marginBottom: 20}
                                    ]}>
                                    <View>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentSpaceBetween,
                                            basicStyles.flexWrap,
                                        ]}>
                                            <View style={[
                                                basicStyles.flexRow,
                                                basicStyles.alignContentCenter
                                            ]}>
                                                {/*todo: use third party image */}
                                                <Avatar
                                                    source={{uri: API_ASSETS + THIRD_PARTY_REVIEW_ICONS[thirdPartyReview.third_party_review_type]}}
                                                    width={51}
                                                    style={{marginRight: 20}}
                                                />
                                                <AppText style={{fontFamily: 'SourceSansPro-Bold'}}>
                                                    {thirdPartyReview.third_party_review_author}
                                                </AppText>
                                            </View>
                                            <View style={[
                                                basicStyles.flexRow,
                                                basicStyles.alignContentCenter
                                            ]}>
                                                <AppText style={{marginRight: 15}}>
                                                    {formatDate(thirdPartyReview.third_party_review_timestamp, 'MMMM D, YYYY')}
                                                </AppText>
                                                <StarRangeInput
                                                    value={thirdPartyReview.third_party_review_rating}
                                                    starSize={16}
                                                    disabled={true}
                                                />
                                                {
                                                    thirdPartyReview.third_party_review_text ?
                                                        <TouchView action={() => toggleReviewTextActive(index)} style={{marginLeft: 15}}>
                                                            {
                                                                reviewTextActive(index) ?
                                                                    <CollapseSvg/>
                                                                    :
                                                                    <ExpandSvg/>
                                                            }
                                                        </TouchView>
                                                        : null
                                                }
                                            </View>
                                        </View>
                                        {
                                            reviewTextActive(index) ?
                                                <View style={{padding: 20}}>
                                                    <View style={{
                                                        borderRadius: 5,
                                                        borderWidth: 1,
                                                        borderColor: '#D9E8F8',
                                                        padding: 20,
                                                    }}>
                                                        <AppText>
                                                            {thirdPartyReview.third_party_review_text}
                                                        </AppText>
                                                    </View>
                                                    <AppButton
                                                        style={{alignSelf: 'flex-end', marginTop: 20}}
                                                        label="Respond"
                                                        action={() => {openReviewRespond(thirdPartyReview)}}
                                                    />
                                                </View>
                                                : null
                                        }
                                    </View>
                                </ContentSection>
                            );
                        })
                        : <View style={basicStyles.flexCenterContent}><AppText>No Records Found</AppText></View>
                }
            </PageContentContainer>
            <Pagination screenName="ThirdPartyReviews" pageCount={state.pageCount} pageIndex={currentPage}/>
        </>
    )
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(ThirdPartyReviews);
