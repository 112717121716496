import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import {basicStyles} from '../styles/basic';
import PageContentContainer from "../components/PageContentContainer";
import ActionRow from "../components/ActionRow";
import Pagination from "../components/Pagination";
import {useDebouncedCallback} from "use-debounce";
import BatchQueueService from "../services/BatchQueueService";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setWarning} from '../store/warning/warningActions';
import AppText from '../components/AppText';
import {formatDate} from "../utilities/methods";

function BatchQueue(props) {
	const [state, setState] = useState({
		users: [],
		organization_id: props.route.params?.organization_id,
		batchQueueData: [],
		initialized: true,
		pageCount: 1,
		paginationRange: [1]
	});
	const {navigate} = props.navigation;
	const {setWarning} = props;
	const perPage = 10;

	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;

	const [getDataDebounce] = useDebouncedCallback(() => {
		const getData = async () => {
			let batchData;
			if (props.route?.params?.organization_id) {
				batchData = await BatchQueueService.getBatchQueuesPaginated(props.route?.params?.organization_id, perPage, currentPage)
			}

			setState(prevState => {
				return Object.assign({}, prevState, {
					initialized: true,
					batchQueueData: batchData.batchQueue,
					pageCount: batchData.pageCount,
					paginationRange: batchData.paginationRange
				});
			});

		};

		getData();
	}, 500);

	const statusAction = (batchQueueItem) => {
		if(batchQueueItem?.batch_queue_status == 'Finished') {
			navigate('ImportHistoryNavigation', {
				screen: 'JobImportResults',
				params: {
					batchQueueId: batchQueueItem.batch_queue_id,
					organizationId: state.organization_id
				}
			})
		} else if(batchQueueItem?.batch_queue_status == 'Error') {
			console.log(batchQueueItem)
			setWarning({
				confirmAction: () => {},
				confirmLabel: 'Close',
				warningMessage: (
					<View>
						<AppText style={styles.exceptionHeader}>An error occurred while processing the import.</AppText>
						{
							batchQueueItem.batch_queue_exception ?
								<AppText style={styles.exceptionCopy}>Error: {batchQueueItem.batch_queue_exception}</AppText>
								: null
						}
						<AppText style={styles.exceptionCopy}>Please try again or contact support.</AppText>
					</View>
				),
				active: true,
			});
		}
	};

	useEffect(() => {
		getDataDebounce();
	}, [props.route.params.organization_id, currentPage]);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			{
				state.initialized && state.batchQueueData ?
					<>
						<PageContentContainer>
							{
								state.batchQueueData
									.map(batchQueueItem => {
										return (
											<ActionRow
												key={batchQueueItem.batch_queue_id}
												action={() => {
													statusAction(batchQueueItem)
												}}
												touchAction={() => {
													statusAction(batchQueueItem)
												}}
												slots={[
													{
														label: 'File Name',
														value: batchQueueItem.batch_queue_file_name,
													},
													{
														label: 'Status',
														value: batchQueueItem.batch_queue_status,
													},
													{
														label: 'Created',
														value: formatDate(batchQueueItem.batch_queue_created_timestamp, 'MM/DD/YYYY hh:mm A'),
													},
													{
														label: 'Processed',
														value: formatDate(batchQueueItem.batch_queue_finished_timestamp, 'MM/DD/YYYY hh:mm A'),
													},
												]}
											/>
										);
									})
							}
						</PageContentContainer>
					</>
					: null
			}
			<Pagination screenName="BatchQueue" pageCount={state.pageCount} pageIndex={currentPage} paginationRange={state.paginationRange} />
		</ScrollView>
	);
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		setWarning,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(BatchQueue);

const styles = StyleSheet.create({
	exceptionHeader: {
		fontSize: 20
	},
	exceptionCopy: {
		fontSize: 15,
		marginTop: 10,
	}
});
