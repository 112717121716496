import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
    async getOrganizations(parentOrganizationId = null, organizationTypeId = null, search = null) {
        let response = await Http().get(API_ROOT + '/organizations/', {
            params: {
                parentOrganizationId,
                organizationTypeId,
                search
            }
        });

        return response.data.organizations;
    },
    async getOrganizationsPaginated(parentOrganizationId = null, organizationTypeId = null, search = null, perPage = null, currentPage = null) {
        let response = await Http().get(API_ROOT + '/organizations/paginated/', {
            params: {
                perPage,
                currentPage,
                parentOrganizationId,
                organizationTypeId,
                search
            }
        });

        return response.data;
    },
    async getOrganization(organizationId) {
        let response = await Http().get(API_ROOT + '/organizations/' + organizationId);

        return response.data.organization;
    },
    async insertOrganization(organization) {
        let response = await Http().post(API_ROOT + '/organizations/', {
            organization
        });

        return response.data.organization;
    },
    async updateOrganization(organizationId, organization) {
        let response = await Http().post(API_ROOT + '/organizations/' + organizationId, {
            organization
        });

        return response.data.organization;
    },
    async uploadImage(formData) {
        let response = await Http().post(API_ROOT + '/organizations/upload-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        return response.data.fileName;
    },
    async registerCard(organizationId, token) {
        let response = await Http().post(API_ROOT + '/organizations/register-card/' + organizationId, {
            token
        });

        return response.data;
    },
    async updateDefaultCard(organizationId, token) {
        let response = await Http().post(API_ROOT + '/organizations/update-default-card/' + organizationId, {
            token
        });

        return response.data;
    },
    async initializeSubscription(organizationId) {
        let response = await Http().post(API_ROOT + '/organizations/initialize-subscription/' + organizationId);

        return response.data.organization;
    },
    async getCustomerInfo(organizationId) {
        let response = await Http().post(API_ROOT + '/organizations/get-customer-info/' + organizationId);

        return response.data;
    },
    async getCustomerInvoices(organizationId) {
        let response = await Http().post(API_ROOT + '/organizations/get-customer-invoices/' + organizationId);

        return response.data.invoices;
    },
    async getPartnerInvoices(partnerId) {
        let response = await Http().post(API_ROOT + '/organizations/get-partner-invoices/' + partnerId);

        return response.data.invoices;
    },
    async getThirdPartyReviews(organizationId, search, startDate, endDate, perPage, currentPage) {
        let response = await Http().get(API_ROOT + '/organizations/get-third-party-reviews/' + organizationId, {
            params: {
                search,
                startDate,
                endDate,
                perPage,
                currentPage
            }
        });

        return response.data;

    },
    async getDashboardReportData(organizationId) {
        let response = await Http().get(API_ROOT + '/organizations/get-dashboard-report-data/' + organizationId);

        return response.data;
    },
    async updateSubscription(organizationId, subscriptionTypeId = null) {
        let response = await Http().post(API_ROOT + '/organizations/update-subscription/' + organizationId + '/' + (subscriptionTypeId ? subscriptionTypeId : ''));

        return response.data.organization;
    },
    async createAddOnService(organizationId, additionalServiceId = null) {
        let response = await Http().post(
            API_ROOT + '/organizations/additional-services/' + organizationId,
            {
                additional_service_id: additionalServiceId
            }
        );
            
        return response.data.organization;
    },
    async removeAddOnService(organizationId, additionalServiceId = null) {
        let response = await Http().post(
            API_ROOT + '/organizations/remove-additional-service/' + organizationId,
            {
                additional_service_id: additionalServiceId
            }
        );

        return response.data.organization;
    },
    async manualReviewRequest(organizationId) {
        try {
            let response = await Http().get(API_ROOT + '/organizations/manual-review-request/' + organizationId);
    
            return response.data.organization;
        } catch (error) {

        }
    },
    async makePayment(organizationId, amount, paymentMethod = null, note = null) {
        await Http().post(API_ROOT + '/organizations/make-payment/' + organizationId, {
            amount,
            paymentMethod,
            note
        });
    },
    async getOrganizationSurvey(organizationId) {
        let response = await Http().get(API_ROOT + '/organizations/get-organization-survey/' + organizationId);

        return response.data.survey;
    },
    async getServiceTerritoryImportConfiguration(organizationId, formData) {
        let response = await Http().post(API_ROOT + '/organizations/get-service-territory-import-configuration/' + organizationId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        return response.data;
    },
    async importServiceTerritories(organizationId, formData) {
        await Http().post(API_ROOT + '/organizations/import-service-territories/' + organizationId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    async getServiceTerritoryData(organizationId) {
        let response = await Http().post(API_ROOT + '/organizations/get-service-territory-data/' + organizationId);

        return response.data.serviceTerritories;

    }
};
