import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useDebouncedCallback} from 'use-debounce';
import moment from 'moment';

import MarketsService from '../../services/MarketsService';
import {ScrollView} from 'react-native';
import {basicStyles} from '../../styles/basic';
import ContentSection from '../../components/ContentSection';
import SpreadSheet from '../../components/SpreadSheet';
import PageContentContainer from '../../components/PageContentContainer';
import {connect} from 'react-redux';
import PageTop from '../../components/PageTop';
import Avatar from '../../components/Avatar';
import {API_ROOT, AVATAR_DEFAULT, AVATAR_PATH_ORG, ORGANIZATION_TYPE_IDS, USA_LAT_LANG_CENTER} from '../../config';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import ReportsSearchSection from '../../components/ReportsSearchSection';
import MapSvg from '../../components/svgs/MapSvg';
import LinesSvg from '../../components/svgs/LinesSvg';
import HeatMap from '../../components/HeatMap';
import AppButton from '../../components/AppButton';
import Pagination from "../../components/Pagination";
import JobsService from "../../services/JobsService";
import EStyleSheet from 'react-native-extended-stylesheet';

function Markets(props) {
    const [state, setState] = useState({
        markets: [],
    });
    const [jobsState, setJobsState] = useState({
        jobs: [],
        jobsInitialized: false
    });
    const [activeTab, setActiveTab] = useState('list');
    const {organization} = props.auth;
    const perPage = 10;
    const [pageCount, setPageCount] = useState(1);
    const [paginationRange, setPaginationRange] = useState([1]);
    const [marketCount, setMarketCount] = useState(0);

    const {navigate} = props.navigation;

    let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : null;
    let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : null;
    let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
    let search = props.route?.params?.search ? props.route.params.search : '';

    const [getMarketsDebounce] = useDebouncedCallback(() => {
        MarketsService.getMarkets(
            organization.organization_id,
            search,
            startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            perPage,
            currentPage).then(marketsData => {
            currentPage = marketsData.currentPage;

            setState(prevState => {
                return {...prevState, markets: marketsData.markets};
            });

            setPageCount(marketsData.pageCount);
            setMarketCount(marketsData.marketCount);
            setPaginationRange(marketsData.paginationRange);
        })
    }, 500);

    const [getJobsDebounce] = useDebouncedCallback(() => {
        setJobsState(prevState => {
            return {...prevState, jobsInitialized: false}
        });
        JobsService.getJobs(
            organization.organization_id,
            null,
            'DESC',
            'job_timestamp',
            startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            500,
            null,
            0,
            true
        ).then(jobsData => {
            setJobsState({
                jobs: jobsData.jobs,
                jobsInitialized: true
            });
        });
    });

    const columns = [
        ...(
            organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID || organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ?
                [
                    {
                        key: 'organization_title',
                        exportName: 'organization_title',
                        label: 'Organization',
                        type: 'text',
                        disabled: true,
                        valueExtractor: market => market.organization_title,
                        onChangeText() {
                        },
                    },
                ]
                : []
        ),
        {
            key: 'market_title',
            exportName: 'market_title',
            label: 'Market',
            type: 'text',
            disabled: true,
            valueExtractor: market => market['market_title'],
            onChangeText() {
            },
        },
        {
            key: 'state',
            exportName: 'state',
            label: 'State',
            type: 'text',
            disabled: true,
            valueExtractor: market => market['google_place_state'],
            onChangeText() {
            },
        },
        {
            key: 'jobs_count',
            exportName: 'jobs_count',
            label: 'Jobs',
            type: 'text',
            disabled: true,
            valueExtractor: market => market['jobs_count'],
            onChangeText() {
            },
            sortCast: 'int',
        },
        {
            key: 'survey_responses_count',
            exportName: 'survey_responses_count',
            label: 'Reviews Sent',
            type: 'text',
            disabled: true,
            valueExtractor: market => market['survey_responses_count'],
            onChangeText() {
            },
            sortCast: 'int',
        },
        {
            key: 'survey_responses_completed_count',
            exportName: 'survey_responses_completed_count',
            label: 'Reviews Completed',
            type: 'text',
            disabled: true,
            valueExtractor: market => market['survey_responses_completed_count'],
            onChangeText() {
            },
            sortCast: 'int',
        },
        {
            key: 'survey_responses_completion_percentage',
            exportName: 'survey_responses_completion_percentage',
            label: 'Reviews Completion %',
            type: 'text',
            disabled: true,
            valueExtractor: market => market['survey_responses_completion_percentage'],
            onChangeText() {
            },
        },
    ];

    function switchTab(tab) {
        setActiveTab(tab);
    }

    function tabIsActive(tab) {
        return activeTab === tab;
    }

    useEffect(() => {
        getMarketsDebounce();
        getJobsDebounce();
    }, [organization.organization_id, startDate, endDate, search, currentPage]);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale}>
            <PageTop style={[
                basicStyles.flexRow,
                basicStyles.alignContentCenter,
                basicStyles.justifyContentSpaceBetween,
                basicStyles.flexWrap,
                styles.headerContainer
            ]}>
                {
                    organization ?
                        <View style={[
                            basicStyles.flexRow,
                            basicStyles.alignContentCenter,
                        ]}>
                            <Avatar
                                source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                                width={66}
                                style={{marginRight: 20}}
                            />
                            <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                                Markets
                            </AppText>
                        </View>
                        : null
                }
                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, {marginTop: 15, marginBottom: 15}]}>
                    <AppButton
                        icon={<LinesSvg fill={tabIsActive('list') ? '#FFFFFF' : '#0065db'}/>}
                        label="List View"
                        theme={tabIsActive('list') ? 'blue' : 'transBlue'}
                        action={() => switchTab('list')}
                        animated={false}
                        style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                    />
                    <AppButton
                        icon={<MapSvg fill={tabIsActive('map') ? '#FFFFFF' : '#0065db'}/>}
                        label="Map View"
                        theme={tabIsActive('map') ? 'blue' : 'transBlue'}
                        action={() => switchTab('map')}
                        animated={false}
                        style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    />
                </View>
            </PageTop>
            <ReportsSearchSection
                onChangeSearch={(value) => {
                    navigate('Markets', {
                        ...props.route?.params,
                        currentPage: 1,
                        search: value
                    });
                }}
                searchValue={search}
                onChangeStartDate={(value) => {
                    navigate('Markets', {
                        ...props.route?.params,
                        startDate: moment(value).format('MM-DD-YYYY'),
                        currentPage: 1,
                    })
                }}
                startDateValue={startDate}
                onChangeEndDate={(value) => {
                    navigate('Markets', {
                        ...props.route?.params,
                        endDate: moment(value).format('MM-DD-YYYY'),
                        currentPage: 1,
                    })
                }}
                endDateValue={endDate}
            />
            { tabIsActive('map') ?
                <View style={tabIsActive('map') ? {} : styles.inactiveTabContainer} {...(!tabIsActive('map') ? {pointerEvents: 'none'} : {})}>
                    <PageContentContainer style={{height: 500}}>
                        {
                            jobsState.jobsInitialized ?
                                <HeatMap
                                    centerLatLang={USA_LAT_LANG_CENTER}
                                    coordinates={
                                        jobsState.jobs.reduce((accumulated, job) => {
                                            if (job.google_place_latitude && job.google_place_longitude) {
                                                accumulated.push({lat: parseFloat(job.google_place_latitude), lng: parseFloat(job.google_place_longitude)})
                                            }

                                            return accumulated
                                        }, [])
                                    }
                                    initialized={jobsState.jobs.length > 0}
                                    autoCenter={true}
                                />
                                :
                                null
                        }
                    </PageContentContainer>
                </View>
                : null
            }
            {
                tabIsActive('list') ?
                    <>
                        <PageContentContainer>
                            <ContentSection style={[
                                basicStyles.spreadSheetWrapper,
                                basicStyles.flexScale,
                            ]}>
                                {
                                    state.markets.length ?
                                        <SpreadSheet
                                            rowCount={marketCount}
                                            rows={state.markets}
                                            columns={columns}
                                            rowKeyExtractor={market => market.google_place_city + '-' + market.google_place_state + '-' + market.organization_id}
                                            exportUrl={API_ROOT + '/organizations/' + props.auth.organization.organization_id + '/markets?search=' + encodeURIComponent(search) + '&startDate=' + encodeURIComponent(startDate ? moment(startDate).format('YYYY-MM-DD') : '') + '&endDate=' + encodeURIComponent(endDate ? moment(endDate).format('YYYY-MM-DD') : '')}
                                            exportTitle="markets.csv"
                                        />
                                        : <View style={basicStyles.flexCenterContent}><AppText>No Records Found</AppText></View>
                                }
                            </ContentSection>
                        </PageContentContainer>
                        <Pagination screenName="Markets" pageCount={pageCount} pageIndex={currentPage} paginationRange={paginationRange}/>
                    </>
                    : null
            }
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(Markets);

const styles = EStyleSheet.create({
    inactiveTabContainer: {
        opacity: 0,
        position: 'absolute',
        pointerEvents: 'none'
    },
    processingText: {
        color: '#FFFFFF',
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 20,
        marginLeft: 20,
    },
    '@media (max-width: 630)': {
        headerContainer: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
});
