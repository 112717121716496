import React from 'react';
import SearchBar from './SearchBar';
import {View} from 'react-native';

import {basicStyles} from '../styles/basic';
import AppDatePicker from './AppDatePicker.web';
import PageTop from './PageTop';

function ReportsSearchSection({
	onChangeSearch,
	searchValue,
	onChangeStartDate,
	startDateValue,
	onChangeEndDate,
	endDateValue,
}) {
	return (
		<PageTop style={
			{
				zIndex: 1,
				elevation: 1,
				overflow: 'visible',
			}
		}>
			<SearchBar
				placeholder="Search"
				onChangeText={(value) => onChangeSearch(value)}
				value={searchValue}
				style={{marginBottom: 30}}
			/>
			<View style={[
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				basicStyles.justifyContentSpaceBetween,
			]}>
				<AppDatePicker
					label="Start Date"
					selectedDate={startDateValue}
					onChange={(value) => onChangeStartDate(value)}
					wrapperStyle={[basicStyles.flexScale, {marginRight: 30}]}
				/>
				<AppDatePicker
					label="End Date"
					selectedDate={endDateValue}
					onChange={(value) => onChangeEndDate(value)}
					wrapperStyle={basicStyles.flexScale}
				/>
			</View>
		</PageTop>
	);
}

export default ReportsSearchSection;
